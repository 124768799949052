import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "module",
	displayName: "Search",
	component: "Search",
	dataPacks: null,
	category: "content",
	configTabs: [
		{
			title: "content",
			fields: [
				{
					key: "searchText",
					type: "TextField",
					title: "Search Text",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],
	default: {
		component: "Search",
		searchText: "Search a study",
		button: {
			component: "Button",
			buttonText: "All studies",
			appearance: "link",
			size: "medium",
			url: {
				href: "",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
	},

	thumbnails: {
		"1x": `/thumbnails/modules/Search/thumbnail.png`,
		"2x": `/thumbnails/modules/Search/thumbnail.png`,
	},
});
